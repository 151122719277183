@import url('@radix-ui/colors/tomato-dark.css');
@import url('@radix-ui/colors/mauve-dark.css');
@import '../node_modules/@mdxeditor/editor/dist/style.css';

@layer base {
  :root {
    --shiki-color-text: theme('colors.white');
    --shiki-token-constant: theme('colors.emerald.300');
    --shiki-token-string: theme('colors.emerald.300');
    --shiki-token-comment: theme('colors.zinc.500');
    --shiki-token-keyword: theme('colors.sky.300');
    --shiki-token-parameter: theme('colors.pink.300');
    --shiki-token-function: theme('colors.violet.300');
    --shiki-token-string-expression: theme('colors.emerald.300');
    --shiki-token-punctuation: theme('colors.zinc.200');
  }

  [inert] ::-webkit-scrollbar {
    display: none;
  }
}

.prose {
  max-width: 75ch !important;
}

.prose a {
  color: #4eb3d4;
}

.ProseMirror img {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.bn-file-caption {
  text-align: center;
}

.border {
  border-color: hsl(var(--border) / 1) !important;
}

.btn {
  font-weight: initial !important;
  font-size: initial !important;
  line-height: initial !important;
  padding: 0 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 98.5%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary-old: 207 61% 57%;
    --primary: 194 58% 58%;
    --primary-new: 351 100% 66%;
    --primary-foreground: 0 0% 100%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 214.3 31.8% 94.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --novel-black: rgb(0 0 0);
    --novel-white: rgb(255 255 255);
    --novel-stone-50: rgb(250 250 249);
    --novel-stone-100: rgb(245 245 244);
    --novel-stone-200: rgb(231 229 228);
    --novel-stone-300: rgb(214 211 209);
    --novel-stone-400: rgb(168 162 158);
    --novel-stone-500: rgb(120 113 108);
    --novel-stone-600: rgb(87 83 78);
    --novel-stone-700: rgb(68 64 60);
    --novel-stone-800: rgb(41 37 36);
    --novel-stone-900: rgb(28 25 23);

    --novel-highlight-default: #ffffff;
    --novel-highlight-purple: #f6f3f8;
    --novel-highlight-red: #fdebeb;
    --novel-highlight-yellow: #fbf4a2;
    --novel-highlight-blue: #c1ecf9;
    --novel-highlight-green: #acf79f;
    --novel-highlight-orange: #faebdd;
    --novel-highlight-pink: #faf1f5;
    --novel-highlight-gray: #f1f1ef;

    --bn-colors-editor-text: #3f3f3f;
    --bn-colors-editor-background: #ffffff;
    --bn-colors-menu-text: #3f3f3f;
    --bn-colors-menu-background: #ffffff;
    --bn-colors-tooltip-text: #3f3f3f;
    --bn-colors-tooltip-background: #efefef;
    --bn-colors-hovered-text: #3f3f3f;
    --bn-colors-hovered-background: #efefef;
    --bn-colors-selected-text: #ffffff;
    --bn-colors-selected-background: #3f3f3f;
    --bn-colors-disabled-text: #afafaf;
    --bn-colors-disabled-background: #efefef;
    --bn-colors-shadow: #cfcfcf;
    --bn-colors-border: #efefef;
    --bn-colors-side-menu: #cfcfcf;
    --bn-colors-highlights-gray-text: #9b9a97;
    --bn-colors-highlights-gray-background: #ebeced;
    --bn-colors-highlights-brown-text: #64473a;
    --bn-colors-highlights-brown-background: #e9e5e3;
    --bn-colors-highlights-red-text: #e03e3e;
    --bn-colors-highlights-red-background: #fbe4e4;
    --bn-colors-highlights-orange-text: #d9730d;
    --bn-colors-highlights-orange-background: #f6e9d9;
    --bn-colors-highlights-yellow-text: #dfab01;
    --bn-colors-highlights-yellow-background: #fbf3db;
    --bn-colors-highlights-green-text: #4d6461;
    --bn-colors-highlights-green-background: #ddedea;
    --bn-colors-highlights-blue-text: #0b6e99;
    --bn-colors-highlights-blue-background: #ddebf1;
    --bn-colors-highlights-purple-text: #6940a5;
    --bn-colors-highlights-purple-background: #eae4f2;
    --bn-colors-highlights-pink-text: #ad1a72;
    --bn-colors-highlights-pink-background: #f4dfeb;
    --bn-font-family: 'Inter', 'SF Pro Display', -apple-system,
      BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --bn-border-radius: 6px;

    --edge-stroke-default: #b1b1b7;
    --edge-stroke-width-default: 1;
    --edge-stroke-selected-default: #555;
    --connectionline-stroke-default: #b1b1b7;
    --connectionline-stroke-width-default: 1;
    --attribution-background-color-default: rgba(255, 255, 255, 0.5);
    --minimap-background-color-default: #fff;
    --background-pattern-dot-color-default: #91919a;
    --background-pattern-line-color-default: #eee;
    --background-pattern-cross-color-default: #e2e2e2;
    --node-color-default: inherit;
    --node-border-default: 1px solid #1a192b;
    --node-background-color-default: #fff;
    --node-group-background-color-default: rgba(240, 240, 240, 0.25);
    --node-boxshadow-hover-default: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
    --node-boxshadow-selected-default: 0 0 0 0.5px #1a192b;
    --handle-background-color-default: #1a192b;
    --handle-border-color-default: #fff;
    --selection-background-color-default: rgba(0, 89, 220, 0.08);
    --selection-border-default: 1px dotted rgba(0, 89, 220, 0.8);
    --controls-button-background-color-default: #fefefe;
    --controls-button-background-color-hover-default: #f4f4f4;
    --controls-button-color-default: inherit;
    --controls-button-color-hover-default: inherit;
    --controls-button-border-color-default: #eee;
    --controls-box-shadow-default: 0 0 2px 1px rgba(0, 0, 0, 0.08);
  }

  .dark {
    --gradient: #4f98d5;
    --background: 207 39.65% 4.56%;
    --foreground: 207 6.1% 97.85%;
    --muted: 207 30.5% 17.099999999999998%;
    --muted-foreground: 207 6.1% 55.7%;
    --popover: 207 52.8% 7.409999999999999%;
    --popover-foreground: 207 6.1% 97.85%;
    --card: 0 0% 5%;
    --card-foreground: 207 6.1% 97.85%;
    --border: 207 30.5% 17.099999999999998%;
    --input: 207 30.5% 17.099999999999998%;
    --primary-old: 207 61% 57%;
    --primary: 194 58% 58%;
    --primary-new: 351 100% 66%;
    --primary-foreground: 0 0% 100%;
    --secondary: 207 60% 7%;
    --secondary-foreground: 207 6.1% 97.85%;
    --accent: 207 30.5% 17.099999999999998%;
    --accent-foreground: 207 6.1% 97.85%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 207 6.1% 97.85%;
    --ring: 207 61% 57%;
    --radius: 0.3rem;

    --novel-black: rgb(255 255 255);
    --novel-white: rgb(25 25 25);
    --novel-stone-50: rgb(35 35 34);
    --novel-stone-100: rgb(41 37 36);
    --novel-stone-200: rgb(66 69 71);
    --novel-stone-300: rgb(112 118 123);
    --novel-stone-400: rgb(160 167 173);
    --novel-stone-500: rgb(193 199 204);
    --novel-stone-600: rgb(212 217 221);
    --novel-stone-700: rgb(229 232 235);
    --novel-stone-800: rgb(232 234 235);
    --novel-stone-900: rgb(240, 240, 241);

    --novel-highlight-default: #000000;
    --novel-highlight-purple: #3f2c4b;
    --novel-highlight-red: #5c1a1a;
    --novel-highlight-yellow: #5c4b1a;
    --novel-highlight-blue: #1a3d5c;
    --novel-highlight-green: #1a5c20;
    --novel-highlight-orange: #5c3a1a;
    --novel-highlight-pink: #5c1a3a;
    --novel-highlight-gray: #3a3a3a;

    --bn-colors-editor-text: 207 6.1% 97.85%;
    --bn-colors-editor-background: 207 39.65% 4.56%;
    --bn-colors-menu-text: 207 6.1% 97.85%;
    --bn-colors-menu-background: 207 39.65% 4.56%;
    --bn-colors-tooltip-text: 207 6.1% 97.85%;
    --bn-colors-tooltip-background: 207 39.65% 4.56%;
    --bn-colors-hovered-text: 207 6.1% 97.85%;
    --bn-colors-hovered-background: 207 39.65% 4.56%;
    --bn-colors-selected-text: #ffffff;
    --bn-colors-selected-background: #3f3f3f;
    --bn-colors-disabled-text: #afafaf;
    --bn-colors-disabled-background: #efefef;
    --bn-colors-shadow: #cfcfcf;
    --bn-colors-border: #efefef;
    --bn-colors-side-menu: #cfcfcf;

    --react-flow-color-mode: dark;
    --edge-stroke-default: #b1b1b7;
    --edge-stroke-width-default: 1;
    --edge-stroke-selected-default: #555;
    --connectionline-stroke-default: #b1b1b7;
    --connectionline-stroke-width-default: 1;
    --attribution-background-color-default: rgba(255, 255, 255, 0.5);
    --minimap-background-color-default: #fff;
    --xy-background-pattern-dot-color-default: #000 !important;
    --node-color-default: inherit;
    --xy-node-border-default: 1px solid
      hsl(var(--border) / var(--tw-border-opacity)) !important;
    --node-background-color-default: #fff;
    --node-group-background-color-default: rgba(240, 240, 240, 0.25);
    --node-boxshadow-hover-default: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
    --node-boxshadow-selected-default: 0 0 0 0.5px #1a192b;
    --handle-background-color-default: #1a192b;
    --handle-border-color-default: #fff;
    --selection-background-color-default: rgba(0, 89, 220, 0.08);
    --selection-border-default: 1px dotted rgba(0, 89, 220, 0.8);
    --controls-button-background-color-default: #fefefe;
    --controls-button-background-color-hover-default: #f4f4f4;
    --controls-button-color-default: inherit;
    --controls-button-color-hover-default: inherit;
    --controls-button-border-color-default: #eee;
    --controls-box-shadow-default: 0 0 2px 1px rgba(0, 0, 0, 0.08);
  }
}

::selection {
  background: #4eb2d4ac;
}

.dark .react-flow__node {
  background: hsl(var(--card) / var(--tw-bg-opacity)) !important;
  color: hsl(var(--card-foreground) / var(--tw-bg-opacity)) !important;
}

.dark .react-flow__controls-button {
  background: hsl(var(--border) / var(--tw-bg-opacity)) !important;
  color: hsl(var(--border-foreground) / var(--tw-bg-opacity)) !important;
}

.dark .react-flow__background {
  background: hsl(var(--background) / var(--tw-bg-opacity)) !important;
  color: rgb(21, 32, 43);
}

.light .react-flow__node {
  background: hsl(var(--card) / var(--tw-bg-opacity)) !important;
  color: hsl(var(--card-foreground) / var(--tw-bg-opacity)) !important;
}

@layer base {
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }

  .container {
    @apply max-sm:px-4;
  }
}

/*
Optimize dropdowns for mobile
 */
[data-radix-popper-content-wrapper] {
  @apply w-full md:w-auto;
}

[data-radix-menu-content] {
  @apply rounded-none md:rounded-lg;
}

[data-radix-menu-content] [role='menuitem'] {
  @apply min-h-12 md:min-h-0;
}

.draggable-item {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
}

.content {
  flex: 1 1 auto;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #4eb3d4;
}

body {
  min-height: 100dvh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: 'Gotham', 'Avenir Next', 'Lato', 'Arial', sans-serif;
  cursor: default;
  overflow-x: hidden;
}

h1 {
  font-style: normal;
  font-weight: bold !important;
  font-size: 38px !important;
  line-height: 64px;
  letter-spacing: -0.02em;
}

h2 {
  font-style: normal;
  font-weight: normal !important;
  font-size: 30px !important;
  line-height: 40px;
  letter-spacing: -0.02em;
}

h3 {
  font-style: normal;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 32px;
  letter-spacing: -0.02em;
}

small {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.tabs-lifted > .tab.tab-active:not(.tab-disabled):not([disabled]):before,
.tabs-lifted > .tab:is(input:checked):before {
  display: none;
}

.marker-highlight {
  position: relative;
}

.marker-highlight:before {
  content: '';
  background-color: #ffffffde;
  width: 105%;
  height: 1.1em;
  position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  top: 0.05em;
  left: -0.1em;
  right: -0.1em;
  bottom: -0.1em;
  padding: 5px;
}

.dark .marker-highlight:before {
  background-color: #070b10de;
}

.marker-highlight-reverse:before {
  content: '';
  background-color: #070b10de;
  width: 105%;
  height: 1.1em;
  position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  top: 0.05em;
  left: -0.1em;
  right: -0.1em;
  bottom: -0.1em;
  padding: 5px;
}

.dark .marker-highlight-reverse:before {
  background-color: #ffffff;
}

.markdown a {
  color: white;
}

.agent-text a:hover {
  color: #4eb3d4;
}

.user-text a:hover {
  color: #162839;
}

.markdown h1 {
  font-size: 2rem;
  margin: 12px 0 6px;
  font-weight: 600;
}

.markdown h2 {
  font-size: 1rem;
  margin: 12px 0 6px;
  font-weight: 600;
}

.markdown h3 {
  margin: 10px 0 4px;
  font-weight: 600;
}

.markdown h4 {
  margin: 8px 0 4px;
  font-weight: 600;
}

.markdown p {
  margin: 12px 0;
}

.markdown p:not(:last-child),
.markdown ol,
.markdown ul {
  margin-bottom: 8px;
}

.markdown ol,
.markdown ul,
.markdown li {
  list-style: auto;
}

.markdown blockquote,
.source-content blockquote,
.tiptap blockquote {
  color: #fff !important;
  border-left: 4px solid #4eb3d4;
  padding-left: 1em;
  font-style: italic;
}

.markdown code {
  color: #4eb3d4;
  font-family:
    'Roboto Mono',
    sfmono-regular,
    consolas,
    liberation mono,
    menlo,
    courier,
    monospace;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  font-size: 0.9em;
}

.markdown a {
  color: #4eb3d4;
}

.markdown hr {
  opacity: 40%;
  margin: 24px 8px;
}

.markdown ul,
.source-content ul {
  list-style-position: inside;
  list-style-type: circle;
  padding: 0;
}

.markdown li,
.source-content li {
  list-style-position: inside;
  list-style-type: disc;
  padding: 0;
}

.markdown ul,
.markdown ol {
  padding-left: 4px;
  margin: 8px 0;
}

.markdown ol,
.source-content ol {
  list-style-type: decimal-leading-zero;
  margin: 12px 0 16px;
}

.markdown li {
  margin-bottom: 3px;
  position: relative;
  padding-left: 10px;
}

.markdown li > p {
  display: inline;
  margin: 12px 0 !important;
}

.markdown ul > li:before {
  content: '' !important;
  margin-right: 0 !important;
}

.markdown img,
.source-content img {
  display: flex;
  max-width: 50%;
  min-width: 300px;
  height: auto;
  margin: 24px auto;
}

.markdown figcaption,
.source-content figcaption {
  font-size: small;
  text-align: center;
  font-style: italic;
}

/* Reset table spacing */
.markdown table &:not(.mdxeditor-rich-text-editor) {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #fff;
  display: block;
  max-width: fit-content;
  margin: 10px 5px 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.markdown table::-webkit-scrollbar {
  height: 6px;
}

.markdown thead th &:not(.mdxeditor-rich-text-editor) {
  background-color: #4eb3d4;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.markdown tbody td &:not(.mdxeditor-rich-text-editor) {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #fff;
}

.markdown tfoot th &:not(.mdxeditor-rich-text-editor) {
  background-color: #4eb3d4;
  padding: 12px;
  text-align: left;
}

.markdown tbody :not(tr:last-child) &:not(.mdxeditor-rich-text-editor) {
  border-right: 1px solid #fff;
}

.mdxeditor-source-editor,
.mdxeditor-diff-editor {
  min-height: 399px;
  background: white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.mdxeditor-rich-text-editor {
  min-height: 400px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.mdxeditor-rich-text-editor ul {
  margin: 12px 0 18px 24px;
}

.mdxeditor-rich-text-editor li {
  line-height: 16px;
}

.mdxeditor-rich-text-editor li:before {
  background-color: #4eb3d4;
  border-color: #4eb3d4;
}

.mdxeditor-rich-text-editor li:after {
  border-color: #fff;
  margin-top: 1px;
}

.mdxeditor-toolbar button {
  background: #111f2c !important;
  color: #fff;
}

.mdxeditor-toolbar button:hover,
.mdxeditor-toolbar button[data-state='on'] {
  background: #22394b !important;
  color: #fff;
}

.mdxeditor-toolbar {
  background: #111f2c !important;
  border: 2px solid #22394b;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.mdxeditor-root-contenteditable {
  background: #22394b;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 0 10px 20px;
}

.mdxeditor-popup-container div[role='dialog'] {
  background-color: #22394b !important;
  color: white !important;
}

.mdxeditor-popup-container div[data-state='open'] {
  background-color: #111f2c !important;
  color: white !important;
}

.mdxeditor-popup-container div[role='option']:hover,
.mdxeditor-popup-container div[data-state='checked'] {
  background-color: #22394b !important;
  color: white !important;
}

.mdxeditor-popup-container div[role='dialog'] button[type='submit'] {
  background: #4eb3d4;
  color: white !important;
}

.mdxeditor-popup-container div[role='dialog'] button {
  color: white !important;
  margin: 6px 0;
}

.mdxeditor-popup-container div[role='dialog'] input {
  color: white !important;
  border: #1a3d5c;
}

.mdxeditor-rich-text-editor table > tbody > tr > td:not(._toolCell_11eqz_675) {
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
}

.bn-shadcn .bn-editor {
  background-color: var(--bg-background) !important;
  color: var(--text-foreground) !important;
}

.bn-block-group .bn-block-group .bn-block-outer:not([data-prev-depth-changed]):before {
  border: 0 !important;
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

.collaboration-cursor__label {
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  font-size: 14px;
  color: #fff;
  top: -1.4em;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  padding: 2px 6px;
  pointer-events: none;
}

.bn-block.bn-block-group {
  margin: 0 0 0 12px !important;
}

.bn-block-content {
  padding: 6px 0 0 !important;
}

p.bn-inline-content {
  padding: 0 0 12px !important;
}

.bn-side-menu {
  padding-top: 5px;
}
.bn-side-menu .bn-button {
  height: 20px !important;
  width: 20px !important;
}
.bn-side-menu .bn-button:not(:first-child) {
  margin-right: 2px !important;
}

.bn-side-menu .bn-button svg {
  height: 20px !important;
  width: 20px !important;
}

.bn-block-content a {
  color: #4eb3d4 !important;
  cursor: pointer;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.markdown-content p {
  margin-bottom: 0.5em;
}

.markdown-content ul,
.markdown-content ol {
  margin-left: 1.5em;
  margin-bottom: 0.5em;
}

.markdown-content code,
.bn-block code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  margin: 0 8px;
}

.markdown-content pre,
.bn-block pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1em;
  overflow-x: auto;
  margin: 0 8px;
}

.dark .markdown-content code,
.dark .bn-block code {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark .markdown-content pre,
.dark .bn-block pre {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 1em;
  overflow-x: auto;
}

.react-tweet-theme {
  --tweet-container-margin: 1.5rem 0;

  /* Header */
  --tweet-header-font-size: 0.9375rem;
  --tweet-header-line-height: 1.25rem;

  /* Text */
  --tweet-body-font-size: 1.25rem;
  --tweet-body-font-weight: 400;
  --tweet-body-line-height: 1.5rem;
  --tweet-body-margin: 0;

  /* Quoted Tweet */
  --tweet-quoted-container-margin: 0.75rem 0;
  --tweet-quoted-body-font-size: 0.938rem;
  --tweet-quoted-body-font-weight: 400;
  --tweet-quoted-body-line-height: 1.25rem;
  --tweet-quoted-body-margin: 0.25rem 0 0.75rem 0;

  /* Info */
  --tweet-info-font-size: 0.9375rem;
  --tweet-info-line-height: 1.25rem;

  /* Actions like the like, reply and copy buttons */
  --tweet-actions-font-size: 0.875rem;
  --tweet-actions-line-height: 1rem;
  --tweet-actions-font-weight: 700;
  --tweet-actions-icon-size: 1.25em;
  --tweet-actions-icon-wrapper-size: calc(
    var(--tweet-actions-icon-size) + 0.75em
  );

  /* Reply button */
  --tweet-replies-font-size: 0.875rem;
  --tweet-replies-line-height: 1rem;
  --tweet-replies-font-weight: 700;
}

:where(.react-tweet-theme) * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:is([data-theme='light'], .light) :where(.react-tweet-theme),
:where(.react-tweet-theme) {
  --tweet-skeleton-gradient: linear-gradient(
    270deg,
    #fafafa,
    #eaeaea,
    #eaeaea,
    #fafafa
  );
  --tweet-border: 1px solid rgb(207, 217, 222);
  --tweet-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif;
  --tweet-font-color: rgb(15, 20, 25);
  --tweet-font-color-secondary: rgb(83, 100, 113);
  --tweet-bg-color: #fff;
  --tweet-bg-color-hover: rgb(247, 249, 249);
  --tweet-quoted-bg-color-hover: rgba(0, 0, 0, 0.03);
  --tweet-color-blue-primary: rgb(29, 155, 240);
  --tweet-color-blue-primary-hover: rgb(26, 140, 216);
  --tweet-color-blue-secondary: rgb(0, 111, 214);
  --tweet-color-blue-secondary-hover: rgba(0, 111, 214, 0.1);
  --tweet-color-red-primary: rgb(249, 24, 128);
  --tweet-color-red-primary-hover: rgba(249, 24, 128, 0.1);
  --tweet-color-green-primary: rgb(0, 186, 124);
  --tweet-color-green-primary-hover: rgba(0, 186, 124, 0.1);
  --tweet-twitter-icon-color: var(--tweet-font-color);
  --tweet-verified-old-color: rgb(130, 154, 171);
  --tweet-verified-blue-color: var(--tweet-color-blue-primary);
}

:is([data-theme='dark'], .dark) :where(.react-tweet-theme) {
  --tweet-skeleton-gradient: linear-gradient(
    270deg,
    #15202b,
    rgb(30, 39, 50),
    rgb(30, 39, 50),
    rgb(21, 32, 43)
  );
  --tweet-border: 1px solid rgb(66, 83, 100);
  --tweet-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif;
  --tweet-font-color: rgb(247, 249, 249);
  --tweet-font-color-secondary: rgb(139, 152, 165);
  --tweet-bg-color: rgb(21, 32, 43);
  --tweet-bg-color-hover: rgb(30, 39, 50);
  --tweet-quoted-bg-color-hover: rgba(255, 255, 255, 0.03);
  --tweet-color-blue-primary: rgb(29, 155, 240);
  --tweet-color-blue-primary-hover: rgb(26, 140, 216);
  --tweet-color-blue-secondary: rgb(107, 201, 251);
  --tweet-color-blue-secondary-hover: rgba(107, 201, 251, 0.1);
  --tweet-color-red-primary: rgb(249, 24, 128);
  --tweet-color-red-primary-hover: rgba(249, 24, 128, 0.1);
  --tweet-color-green-primary: rgb(0, 186, 124);
  --tweet-color-green-primary-hover: rgba(0, 186, 124, 0.1);
  --tweet-twitter-icon-color: var(--tweet-font-color);
  --tweet-verified-old-color: rgb(130, 154, 171);
  --tweet-verified-blue-color: #fff;
}

@media (prefers-color-scheme: dark) {
  :where(.react-tweet-theme) {
    --tweet-skeleton-gradient: linear-gradient(
      270deg,
      #15202b,
      rgb(30, 39, 50),
      rgb(30, 39, 50),
      rgb(21, 32, 43)
    );
    --tweet-border: 1px solid rgb(66, 83, 100);
    --tweet-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif;
    --tweet-font-color: rgb(247, 249, 249);
    --tweet-font-color-secondary: rgb(139, 152, 165);
    --tweet-bg-color: rgb(21, 32, 43);
    --tweet-bg-color-hover: rgb(30, 39, 50);
    --tweet-color-blue-primary: rgb(29, 155, 240);
    --tweet-color-blue-primary-hover: rgb(26, 140, 216);
    --tweet-color-blue-secondary: rgb(107, 201, 251);
    --tweet-color-blue-secondary-hover: rgba(107, 201, 251, 0.1);
    --tweet-color-red-primary: rgb(249, 24, 128);
    --tweet-color-red-primary-hover: rgba(249, 24, 128, 0.1);
    --tweet-color-green-primary: rgb(0, 186, 124);
    --tweet-color-green-primary-hover: rgba(0, 186, 124, 0.1);
    --tweet-twitter-icon-color: var(--tweet-font-color);
    --tweet-verified-old-color: rgb(130, 154, 171);
    --tweet-verified-blue-color: #fff;
  }
}
